import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { useAppSelector } from "stores";
import { Typography } from "antd";

const { Title } = Typography;

export default function StakingChart() {
  const { dailyStaking } = useAppSelector((state) => state.general);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (dailyStaking) {
      let arr = [] as any;
      dailyStaking.forEach((item) => {
        if (item.totalStakeVolume) {
          const stakeItem = {
            name: "Total Stake Volume",
            time: item._id,
            value: item.totalStakeVolume,
          };
          arr.push(stakeItem);
        }
        if (item.totakUnstakeVolume) {
          const unstakeItem = {
            name: "Total Unstake Volume",
            time: item._id,
            value: item.totakUnstakeVolume,
          };
          arr.push(unstakeItem);
        }
      });
      setData(arr);
    }
  }, [dailyStaking]);

  const config = {
    data: data,
    isGroup: true,
    xField: "time",
    yField: "value",
    seriesField: "name",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return (
    <div className="form-item-container">
      <div style={{ marginBottom: 30 }}>
        <Title level={5}>Staking</Title>
      </div>
      {
        //@ts-ignore
        <Column {...config} />
      }
    </div>
  );
}
