import { Button, Descriptions, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { useAppSelector } from "stores";
import { EditOutlined } from "@ant-design/icons";
import PopupUpdateVipLevel from "./PopupUpdateVipLevel";
import { Helpers } from "utils";

const { Text } = Typography;

export default function StakingInfo() {
  const { detail, userPredictPnl } = useAppSelector((state) => state.user);
  const [isUpdateLevel, setIsUpdateLevel] = useState(false);

  return (
    <div className="form-item-container">
      <Descriptions column={6} title="Staking Info">
        <Descriptions.Item span={3} label="VIP">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Text strong style={{ marginRight: 10 }}>
              {detail?.userCommission.stakingSaleLevel}
            </Text>
            <Tooltip title="Edit user VIP level">
              <Button
                onClick={() => setIsUpdateLevel(true)}
                type="link"
                icon={<EditOutlined />}
              ></Button>
            </Tooltip>
          </div>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Sales">
          <Text strong>{detail?.userCommission.totalStakingSale} USD</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Staking">
          <Text strong>
            {Helpers.checkInt(detail?.staking?.stakingValue || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Commission">
          <Text strong>
            {Helpers.checkInt(detail?.staking?.commissionValue || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Profit">
          <Text strong>
            {Helpers.checkInt(detail?.staking?.harvestValue || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Downline">
          <Text strong>{detail?.totalDownlineMember} User</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Harvest">
          <Text strong>
            {Helpers.checkInt(detail?.staking?.totalHarvestValue || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Claim">
          <Text strong>
            {Helpers.checkInt(
              detail?.staking?.totalCommissionValue -
                detail?.staking?.commissionValue
            )}{" "}
            USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Unstake">
          <Text strong>
            {Helpers.checkInt(detail?.staking?.totalUnstakedValue || 0)} USD
          </Text>
        </Descriptions.Item>
        {/* <Descriptions.Item span={3} label="Claim Available">
          <Text strong>
            {Helpers.checkInt(
              detail?.staking?.commissionValue -
                detail?.staking?.totalInterestValue
            )}{" "}
            USD
          </Text>
        </Descriptions.Item> */}
        {/* <Descriptions.Item span={3} label="Earned">
          <Text strong>
            {Helpers.checkInt(
              detail?.staking?.harvestValue +
                detail?.staking?.totalHarvestValue +
                detail?.staking?.commissionValue
            )}{" "}
            USD
          </Text>
        </Descriptions.Item> */}
      </Descriptions>
      <PopupUpdateVipLevel
        isOpen={isUpdateLevel}
        defaultLevel={detail?.userCommission.stakingSaleLevel}
        handleCancel={() => setIsUpdateLevel(false)}
      />
    </div>
  );
}
