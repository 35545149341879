import { RequestManager } from "utils";
import config from "configs";

const setting = () => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/settings/`
  );
};

const iqtSetting = () => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/settings/iqt`
  );
};

const eiqtSetting = () => {
  return RequestManager.v1.withAuthorize.get(
      `${config.api_base_url}/admin/settings/eiqt`
  );
};

const iqtSettingUpdate = (params: any) => {
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/settings/iqt`,
    params
  );
};

const eiqtSettingUpdate = (params: any) => {
  return RequestManager.v1.withAuthorize.put(
      `${config.api_base_url}/admin/settings/eiqt`,
      params
  );
};

const updateSetting = (params: any) => {
  const { key, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/settings/${key}`,
    otherParams
  );
};

const exported = {
  setting,
  updateSetting,
  iqtSetting,
  iqtSettingUpdate,
  eiqtSetting,
  eiqtSettingUpdate

};

export default exported;
