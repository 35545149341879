import React, {useContext, useEffect, useMemo, useState} from "react";
import Header from "./components/Header";
import { Collapse } from "antd";
import LiveRound from "./components/LiveRound";
import { useAppDispatch, useAppSelector } from "stores";
import PredictionSlice, { listRound } from "stores/PredictionSlice";
import { SocketContext } from "contexts/socketContext";
import NextRound from "./components/NextRound";
import UserPrediction from "./components/UserPrediction";
import PopupChooseResult from "./components/PopupChooseResult";
import _ from "lodash";
const { Panel } = Collapse;

export default function PredictionPage() {
  const socket = useContext(SocketContext);
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.profile).token;
  const admin = useAppSelector((state) => state.profile).admin;
  const rounds = useAppSelector((state) => state.prediction).rounds;
  const isRefresh = useAppSelector((state) => state.prediction).isRefresh;

  const getActiveRounds = (rounds: RoundPredictType[]) => {
    return rounds.filter((round: RoundPredictType) => round.status === "LIVE" || round.status === "NEXT");
  }
  const activeRounds = useMemo(() => getActiveRounds(rounds), [rounds]);

  useEffect(() => {
    if (token && admin.role && (admin.role === "SUPPER_ADMIN" || admin.role === "SYSTEM")) {
      dispatch(listRound());
      if (socket) {
        socket.on("users/UPDATE_LAST_PRICE", function (data) {
          const parsedData = JSON.parse(data);
          dispatch(PredictionSlice.actions.setLastPrice(parsedData.lastPrice));
        });

        //Time frame 1
        socket.on("games/NEW_ROUND_1", function (data) {
          // Add new round
          const round: any = JSON.parse(data);
          dispatch(PredictionSlice.actions.addNewRound(round));
        });
        socket.on("games/START_LIVE_ROUND_1", function (data) {
          const round: any = JSON.parse(data);
          dispatch(PredictionSlice.actions.updateRound(round));
        });
        socket.on("games/UPDATE_PREDICT_ROUND_1", function (data) {
          const round: any = JSON.parse(data);
          round.id = round._id;
          delete round._id;
          dispatch(PredictionSlice.actions.updateRound(round));
        });
        socket.on("games/LIVE_ROUND_PROCESSING_1", function (data) {
          const round: any = JSON.parse(data);

          dispatch(PredictionSlice.actions.liveProcessing(round));
        });
        socket.on("games/LIVE_ROUND_COMPLETED_1", function (data) {
          const round: any = JSON.parse(data);
          dispatch(PredictionSlice.actions.liveProcessCompleted(round));
        });
        socket.on("games/NEW_USER_PREDICT_PREDICT_1", function (data) {
          const userPredict: any = JSON.parse(data);
          userPredict.id = userPredict._id;
          delete userPredict._id;
          dispatch(PredictionSlice.actions.newUserPredict(userPredict));
        });

        // Timeframe 3
        socket.on("games/NEW_ROUND_3", function (data) {
          // Add new round
          const round: any = JSON.parse(data);
          dispatch(PredictionSlice.actions.addNewRound(round));
        });
        socket.on("games/START_LIVE_ROUND_3", function (data) {
          const round: any = JSON.parse(data);
          dispatch(PredictionSlice.actions.updateRound(round));
        });
        socket.on("games/UPDATE_PREDICT_ROUND_3", function (data) {
          const round: any = JSON.parse(data);
          round.id = round._id;
          delete round._id;
          dispatch(PredictionSlice.actions.updateRound(round));
        });
        socket.on("games/LIVE_ROUND_PROCESSING_3", function (data) {
          const round: any = JSON.parse(data);

          dispatch(PredictionSlice.actions.liveProcessing(round));
        });
        socket.on("games/LIVE_ROUND_COMPLETED_3", function (data) {
          const round: any = JSON.parse(data);
          dispatch(PredictionSlice.actions.liveProcessCompleted(round));
        });
        socket.on("games/NEW_USER_PREDICT_PREDICT_3", function (data) {
          const userPredict: any = JSON.parse(data);
          userPredict.id = userPredict._id;
          delete userPredict._id;
          dispatch(PredictionSlice.actions.newUserPredict(userPredict));
        });
      }
      return () => {
        if (socket) {
          socket.off("users/UPDATE_LAST_PRICE");
          socket.off("games/NEW_ROUND_1");
          socket.off("games/START_LIVE_ROUND_1");
          socket.off("games/UPDATE_PREDICT_ROUND_1");
          socket.off("games/LIVE_ROUND_PROCESSING_1");
          socket.off("games/LIVE_ROUND_COMPLETED_1");
          socket.off("games/NEW_USER_PREDICT_PREDICT_1");
          socket.off("games/UPDATE_PREDICT_ROUND_1");

          socket.off("games/NEW_ROUND_3");
          socket.off("games/START_LIVE_ROUND_3");
          socket.off("games/UPDATE_PREDICT_ROUND_3");
          socket.off("games/LIVE_ROUND_PROCESSING_3");
          socket.off("games/LIVE_ROUND_COMPLETED_3");
          socket.off("games/NEW_USER_PREDICT_PREDICT_3");
          socket.off("games/UPDATE_PREDICT_ROUND_3");
        }
      };
    }
  }, [token, dispatch, admin]);

  useEffect(() => {
    if (isRefresh && token &&  admin.role && (admin.role === "SUPPER_ADMIN" || admin.role === "SYSTEM")) {
      dispatch(listRound());
    }
  }, [isRefresh, admin]);

  const [activeKey, setActiveKey] = useState("2");

  useEffect(() => {
    if (activeRounds) {
      const nextRound: any = rounds.find((round) => round.status === "NEXT" && round.timeframe === 1);
      if (nextRound && activeKey === "2") {
        setActiveKey(nextRound.id)
      }
    }
  }, [activeRounds])

  return (
    <div>
      <Header />
      <div className="content-container">
        <h2>Timeframe: 1M</h2>
        <div className="list-collapse-container">
          <Collapse
            onChange={(e) => setActiveKey(e[0])}
            activeKey={activeKey}
            accordion
            className="round-collapse"
          >
            {
              activeRounds.filter((item: RoundPredictType) => item.timeframe === 1).map((round: RoundPredictType) => {
                return (
                  <Panel header={round.status === "LIVE" ? <LiveRound round={round}/> : <NextRound round={round}/>}
                         key={round.id}>
                    <UserPrediction userPredicts={round.userPredicts}/>
                  </Panel>
                )
              })
            }
          </Collapse>
        </div>

        <h2 className={"mt-10"}>Timeframe: 3M</h2>
        <div className="list-collapse-container">
          <Collapse
            onChange={(e) => setActiveKey(e[0])}
            activeKey={activeKey}
            accordion
            className="round-collapse"
          >
            {
              activeRounds.filter((item: RoundPredictType) => item.timeframe === 3).map((round: RoundPredictType) => {
                return (
                  <Panel header={round.status === "LIVE" ? <LiveRound round={round}/> : <NextRound round={round}/>}
                         key={round.id}>
                    <UserPrediction userPredicts={round.userPredicts}/>
                  </Panel>
                )
              })
            }
          </Collapse>
        </div>
      </div>
      <PopupChooseResult />
    </div>
  );
}
