import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function FarmingGeneralView() {
    const { farmingGeneral } = useAppSelector((state) => state.general);
    return (
        <div className="form-item-container">
            <Descriptions title="Farming">
                <Descriptions.Item label="Total LP Added">
                    <Text strong>
                        {Helpers.checkInt(farmingGeneral?.totalValue || 0)} USD
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total LP Closed">
                    <Text strong>{farmingGeneral?.totalClosedLP || 0} USD</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total LP Active">
                    <Text strong>
                        {Helpers.checkInt(farmingGeneral?.totalValue - farmingGeneral?.totalClosedLP || 0)} USD
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Profit">
                    <Text strong>
                        {Helpers.checkInt(farmingGeneral?.totalProfit || 0)} USD
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Commission">
                    <Text strong>
                        {Helpers.checkInt(farmingGeneral?.totalCommission || 0)} USD
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Claimed">
                    <Text strong>
                        {Helpers.checkInt(farmingGeneral?.totalClaim || 0)} USD - {Helpers.checkInt(farmingGeneral?.totalEIQTClaim || 0)} eIQT
                    </Text>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
}
