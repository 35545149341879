import { Button, Card, Form, Input, Space } from "antd";
import SettingApi from "apis/SettingApi";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import CommonSlice from "stores/CommonSlice";

export default function WithdrawSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { setting } = useAppSelector((state) => state.setting);
  const usdt = setting.find((item) => item.key === "bep20_usdt_withdraw_fee");
  const iqt = setting.find((item) => item.key === "bep20_iqt_withdraw_fee");
  const eiqt = setting.find((item) => item.key === "bep20_eiqt_withdraw_fee");
  const bnb = setting.find((item) => item.key === "bep20_bnb_withdraw_fee");
  const [loading, setLoading] = useState(false);

  console.log(usdt, iqt);

  useEffect(() => {
    if (usdt && iqt) {
      form.setFieldsValue({
        usdt: usdt.value * 100,
        iqt: iqt.value * 100,
        eiqt: eiqt?.value * 100,
        bnb: bnb?.value * 100,
      });
    }
  }, [usdt, iqt]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const usdtParams = {
        key: "bep20_usdt_withdraw_fee",
        value: parseFloat(values.usdt) / 100,
      };
      const iqtParanms = {
        key: "bep20_iqt_withdraw_fee",
        value: parseFloat(values.iqt) / 100,
      };
      const eiqtParanms = {
        key: "bep20_eiqt_withdraw_fee",
        value: parseFloat(values.eiqt) / 100,
      };
      const bnbParanms = {
        key: "bep20_bnb_withdraw_fee",
        value: parseFloat(values.bnb) / 100,
      };
      const [result1, result2, result3, result4] = await Promise.all([
        SettingApi.updateSetting(usdtParams),
        SettingApi.updateSetting(iqtParanms),
        SettingApi.updateSetting(eiqtParanms),
        SettingApi.updateSetting(bnbParanms)
      ]);
      if (result1 && result2 && result3 && result4) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title="Withdraw Fee" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label={`${usdt?.description} (USDT)`}
          name="usdt"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={`${iqt?.description} (IQT)`}
          name="iqt"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
            label={`${eiqt?.description} (eIQT)`}
            name="iqt"
            rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
            label={`${bnb?.description} (BNB)`}
            name="bnb"
            rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
