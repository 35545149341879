import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function StakeGeneralView() {
  const { stakeGeneral } = useAppSelector((state) => state.general);
  return (
    <div className="form-item-container">
      <Descriptions title="Staking">
        <Descriptions.Item label="Staking">
          <Text strong>
            {Helpers.checkInt(stakeGeneral?.totalStakeAmount || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Profit">
          <Text strong>{stakeGeneral?.totalInterest || 0}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Commission">
          <Text strong>
            {Helpers.checkInt(stakeGeneral?.totalCommission || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Unstake">
          <Text strong>
            {Helpers.checkInt(stakeGeneral?.totalUnstakeAmount || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Harvest">
          <Text strong>
            {Helpers.checkInt(stakeGeneral?.totalHarvestAmount || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Claim">
          <Text strong>
            {Helpers.checkInt(stakeGeneral?.totalCommissionHarvest || 0)}
          </Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
