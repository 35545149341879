import { Button, Dropdown, Layout, Menu, MenuProps } from "antd";
import { menusFull, menusAdmin } from "./menus";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "stores";
import ProfileSlice, {adminInfo} from "stores/ProfileSlice";
import {useEffect} from "react";
import {setting} from "../../stores/SettingSlice";

const { Header, Content, Sider } = Layout;

export default function MainLayout() {
  const dispatch = useAppDispatch();
    const { token, admin } = useAppSelector((state) => state.profile);
  const navigate = useNavigate();
  let location = useLocation();
  function logOut() {
    dispatch(ProfileSlice.actions.setToken(""));
    localStorage.removeItem("AccessToken");
    navigate("/login");
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      icon: <LogoutOutlined />,
      label: <label onClick={logOut}>Logout</label>,
    },
  ];

  const onClickMenu = (e: any) => {
    navigate(e.key);
  };

    useEffect(() => {
        if (token) {
            dispatch(adminInfo());
        }
    }, [token]);

  return (
    <Layout hasSider id={"admin-iq"}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: "rgba(255, 255, 255, 0.2)",
          }}
        />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={["/" + location.pathname.split("/")[1]]}
          items={admin.role === "SUPPER_ADMIN" || admin.role === "SYSTEM" ? menusFull : menusAdmin}
          onClick={onClickMenu}
        />
      </Sider>
      <Layout style={{ background: "#e5e5e5" }} className="site-layout">
        <Header
          className="header"
          style={{
            paddingRight: 20,
            background: "#fff",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Dropdown menu={{ items }}>
            <Button>
              Hi <strong style={{ marginLeft: 10 }}>{admin.username}</strong>
              <DownOutlined />
            </Button>
          </Dropdown>
        </Header>
        <Content
          style={{ marginTop: 10, margin: "16px auto", maxWidth: "1300px"}}
          className="site-layout-background content"
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
