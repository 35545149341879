import { Card, Typography } from "antd";
import { Helpers } from "utils";
import HeaderRound from "./HeaderRound";
import _ from "lodash";
import {useMemo} from "react";
const { Text } = Typography;

type Props = {
  round: RoundPredictType;
};

export default function NextRound(props: Props) {
  const { round } = props;
  return (
    <div style={{ border: "1px solid #000", padding: 16 }}>
      <HeaderRound round={round} />
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text strong>UP</Text>
          <Text strong>{Helpers.checkInt(round.rateUp + 1)}x/{Helpers.checkInt(round.rateUpToken + 1)}x Payout</Text>
          <div style={{display: "flex", flexDirection: "row", marginTop: 5}}>
            <Text strong>Amount: </Text>
            <div style={{display: "flex", flexDirection: "column", marginLeft: 10}}>
              <Text strong>{Helpers.checkInt(round.userUpAmount)} USD</Text>
              <Text strong>{Helpers.checkInt(round.userUpTokenAmount)} IQT</Text>
            </div>
          </div>
        </div>
        <Card
          style={{
            width: 300,
            borderWidth: 2,
            borderColor: "#FCD535",
          }}
          bordered
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text>Real Prize Pool</Text>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Text strong>{Helpers.checkInt(round.userUpAmount + round.userDownAmount)} USD</Text>
              <Text strong>{Helpers.checkInt(round.userUpTokenAmount + round.userDownTokenAmount)} IQT</Text>
            </div>
          </div>
        </Card>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text strong>DOWN</Text>
          <Text strong>{Helpers.checkInt(round.rateDown + 1)}x/{Helpers.checkInt(round.rateDownToken + 1)}x Payout</Text>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
            <Text strong>Amount: </Text>
            <div style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}>
              <Text strong>{Helpers.checkInt(round.userDownAmount)} USD</Text>
              <Text strong>{Helpers.checkInt(round.userDownTokenAmount)} IQT</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
