import { Table, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import moment from "moment";

const { Text } = Typography;

type Props = {
  onChangePage: (page: number) => void;
  filter: any;
};

export default function List(props: Props) {
  const datas = useAppSelector((state) => state.user).stakeHistory.datas;
  const meta = useAppSelector((state) => state.user).stakeHistory.meta;

  const columns: any = [
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (value: Date) => moment(value).format("YYYY-MM-DD hh:mm:ss"),
    },
    {
      title: "Token Amount (IQT)",
      dataIndex: "tokenAmount",
      key: "tokenAmount",
      align: "center",
    },
    {
      title: "Token Price",
      dataIndex: "tokenPrice",
      key: "tokenPrice",
      align: "center",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
      {
          title: "Type",
          dataIndex: "historyType",
          key: "historyType",
          align: "center",
      },
  ];

  return (
    <div className="list-container">
      <Table
        columns={columns}
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 5,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          onChange: (page) => props.onChangePage(page),
          showTotal: (total: number) => `Total: ${total}`,
        }}
      />
    </div>
  );
}
