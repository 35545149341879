import { RequestManager } from "utils";
import config from "configs";

const generalPromotion = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/promotions/general`,
    params
  );
};

const userJoin = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/promotions/`,
    params
  );
};

const exported = {
  generalPromotion,
  userJoin,
};

export default exported;
