import { Table, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import moment from "moment";

const { Text } = Typography;

type Props = {
  onChangePage: (page: number) => void;
  filter: any;
};

const textEllipseMiddle = (str: string) => {
  if (str && str.length > 10) {
    return str.substr(0, 5) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
};

const CustomText = ({ status, value }: any) => {
  return (
    <Text
      strong
      type={
        status === "SUCCESSFUL" ? "success" : status === "PENDING" ? "secondary" : undefined
      }
    >
      {value}
    </Text>
  );
};

export default function List(props: Props) {
  const datas = useAppSelector((state) => state.user).transactionHistory.datas;
  const meta = useAppSelector((state) => state.user).transactionHistory.meta;

  const getStatusLabel = (status: string): string => {
    switch (status) {
      case "SUCCESSFUL":
        return "Successful";
      case "PENDING":
        return "Pending";
      case "WAIT_ADMIN_CONFIRM":
        return "Wait confirm";
      case "FAILED":
        return "Failed";
      case "CANCELLED":
        return "Cancelled";
      default:
        return "Processing";
    }
  }

  const columns: any = [
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (value: string, record: any) => <CustomText value={value} />,
      align: "left",
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "network",
      render: (value: string, record: any) => <CustomText value={value || "Internal"} />,
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value: string, record: any) => `${value} ${record.coinType}`,
      align: "center",
    },
    {
      title: "Wallet address",
      dataIndex: "walletReceiver",
      key: "walletReceiver",
      render: (value: string, record: any) => {
        return (
          <Typography.Text copyable={{ text: value }}>
            {textEllipseMiddle(value)}
          </Typography.Text>
        )
      },
      align: "center",
    },
    {
      title: "TxHash",
      dataIndex: "txHash",
      key: "txHash",
      render: (value: string, record: any) => {
        return (
          <>
            { value && <Typography.Text copyable={{ text: value }}>
              {textEllipseMiddle(value)}
            </Typography.Text>}
          </>
        )
      },
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => <CustomText status={value} value={getStatusLabel(value)} />,
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => moment(value).format("YYYY-MM-DD hh:mm"),
      align: "right",
    },
  ];

  return (
    <div className="list-container">
      <Table
        columns={columns}
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 5,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          onChange: (page) => props.onChangePage(page),
          showTotal: (total: number) => `Total: ${total}`,
        }}
      />
    </div>
  );
}
