import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import Header from "./components/Header";
import UserSlice, {getUserPredictPnl, getUserDetail, getUserTokenPredictPnl} from "stores/UserSlice";
import Content from "./components/Content";
export default function DetailUserPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const accessToken = useAppSelector((state) => state.profile).token;

  const { isRefresh } = useAppSelector((state) => state.user);
  const fetchData = () => {
    if (id && accessToken) {
      dispatch(getUserPredictPnl(id));
      dispatch(getUserTokenPredictPnl(id));
      dispatch(getUserDetail(id));
    }
  };

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      dispatch(UserSlice.actions.setIsRefresh(false));
    }
  }, [isRefresh]);

  useEffect(() => {
    fetchData();
  }, [id, accessToken]);
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
}
