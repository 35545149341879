export const filterOptions = [
  {
    label: "Status",
    value: "withStatus",
  },
  {
    label: "Network",
    value: "withNetwork",
  },
  {
    label: "Coin Type",
    value: "withCoinType",
  },
];

export const optionMultiple = [] as string[];

export const UserCoinTransactionStatus = [
  {
    label: "WAIT ADMIN CONFIRM",
    value: "WAIT_ADMIN_CONFIRM",
  },
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "WAIT NETWORK CONFIRM",
    value: "WAIT_NETWORK_CONFIRM",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "SUCCESSFUL",
    value: "SUCCESSFUL",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
];

export const Network = [
  {
    label: "ETH",
    value: "ETH",
  },
  {
    label: "BSC",
    value: "BSC",
  },
  {
    label: "TRX",
    value: "TRX",
  },
  {
    label: "NEAR",
    value: "NEAR",
  },
  {
    label: "UNKNOWN",
    value: "UNKNOWN",
  },
];

export const coinTypes = [
  {
    label: "USDT",
    value: "USDT",
  },
  {
    label: "IQT",
    value: "IQT",
  },
];
