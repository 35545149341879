import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function BalanceView() {
  const { general } = useAppSelector((state) => state.general);

  return (
    <div className="form-item-container">
      <Descriptions column={12} title="Balance">
        <Descriptions.Item span={4} label="USDT Balance">
          <Text strong>{Helpers.checkInt(general?.totalBalance || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={4} label="USDT Deposit">
          <Text strong>{Helpers.checkInt(general?.totalDeposit || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={4} label="USDT Withdraw">
          <Text strong>{Helpers.checkInt(general?.totalWithdraw || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={4} label="IQT Balance">
          <Text strong>
            {Helpers.checkInt(general?.totalTokenBalance || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={4} label="IQT Deposit">
          <Text strong>
            {Helpers.checkInt(general?.totalTokenDeposit || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={4} label="IQT Withdraw">
          <Text strong>
            {Helpers.checkInt(general?.totalTokenWithdraw || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="esIQT">
          <Text strong>{Helpers.checkInt(general?.totalTokenEs || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={4} label="IQT Locked">
          <Text strong>{Helpers.checkInt(general?.totalTokenFrozen || 0)}</Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
