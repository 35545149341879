import { Col, Descriptions, Typography, Row, List } from "antd";
import React from "react";
import { Helpers } from "utils";
import { decimalAdjustFloor3 } from "utils/Helpers";

const { Text } = Typography;

type Props = {
  userPredicts: UserPredictType[];
};

export default function UserPrediction(props: Props) {
  const { userPredicts } = props;
  return (
    <Row>
      <Col span={12}>
        <div style={{ borderRight: "1px solid #000", padding: "0 16px" }}>
          <div>
            <Text strong type="success">
              Position Up
            </Text>
          </div>
          <List
            dataSource={
              userPredicts &&
              userPredicts.filter((item) => item.position === "UP")
            }
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta title={item.user.email} />
                <Text strong>{decimalAdjustFloor3(item.amount)} {item.coinType}</Text>
              </List.Item>
            )}
          />
        </div>
      </Col>
      <Col span={12}>
        <div style={{ padding: "0 16px" }}>
          <div>
            <Text strong type="danger">
              Position Down
            </Text>
          </div>
          <List
            dataSource={
              userPredicts &&
              userPredicts.filter((item) => item.position === "DOWN")
            }
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta title={item.user.email} />
                <Text strong>{decimalAdjustFloor3(item.amount)} {item.coinType}</Text>
              </List.Item>
            )}
          />
        </div>
      </Col>
    </Row>
  );
}
