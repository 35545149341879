import { Button, Card, Form, Input, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import SettingApi from "apis/SettingApi";
import CommonSlice from "stores/CommonSlice";

export default function TokenPredictionSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { setting } = useAppSelector((state) => state.setting);
  const roundProfit = setting.find((item) => item.key === "round_profit");
  const defaultPredict1m = setting.find((item) => item.key === "default_predict_token_1");
  const defaultPredict3m = setting.find((item) => item.key === "default_predict_token_3");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (roundProfit && defaultPredict1m && defaultPredict3m) {
      form.setFieldsValue({
        roundProfit: roundProfit.value,
        defaultPredict1m: defaultPredict1m.value,
        defaultPredict3m: defaultPredict3m.value,
      });
    }
  }, [roundProfit, defaultPredict1m, defaultPredict3m]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const roundProfitParams = {
        key: "round_profit",
        value: parseFloat(values.roundProfit),
      };
      const defaultPredict1Params = {
        key: "default_predict_token_1",
        value: parseFloat(values.defaultPredict1m),
      };
      const defaultPredict3Params = {
        key: "default_predict_token_3",
        value: parseFloat(values.defaultPredict3m),
      };
      const [result1, result2, result3] = await Promise.all([
        SettingApi.updateSetting(roundProfitParams),
        SettingApi.updateSetting(defaultPredict1Params),
        SettingApi.updateSetting(defaultPredict3Params),
      ]);
      if (result1 && result2 && result3) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title="Prediction (IQT)" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Row style={{ margin: 0 }} gutter={[16, 16]} className="">
          <Col span={24}>
            <Form.Item
              label="Round Profit"
              name="roundProfit"
              rules={[{ required: true, message: "Please input your value!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Default Predict 1M (IQT)"
              name="defaultPredict1m"
              rules={[{ required: true, message: "Please input your value!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Default Predict 3M (IQT)"
              name="defaultPredict3m"
              rules={[{ required: true, message: "Please input your value!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
