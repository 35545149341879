import { RequestManager } from "utils";
import config from "configs";

const getUsers = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users`,
    params
  );
};

const getUserDetail = (userId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}`
  );
};

const getUserPredictPnl = (userId: string, coinType: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/predict-pnl?token=${coinType}`
  );
};

const getUserFriendVolume = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/friend-volume`,
    otherParams
  );
};

const getUserCommissionRebate = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/histories`,
    otherParams
  );
};

const getUserPredictHistories = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/predict-histories`,
    otherParams
  );
};

const getUserCoinTransactionHistories = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/transaction-histories`,
    {
      ...otherParams,
      transactionType: ["DEPOSIT", "WITHDRAW"]
    }
  );
};

const updateUser = (params: any) => {
  const { id, ...ortherParams } = params;
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/users/${id}`,
    ortherParams
  );
};

const getUserStakeHistories = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/stake-histories`,
    otherParams
  );
};

const getUserInterestHistories = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/interest-histories`,
    otherParams
  );
};

const getUserCommissionHistories = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/commission-histories`,
    otherParams
  );
};

const updateVip = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/users/${userId}/vip`,
    otherParams
  );
};

const exported = {
  getUsers,
  getUserPredictPnl,
  getUserFriendVolume,
  getUserPredictHistories,
  getUserDetail,
  updateUser,
  getUserCommissionRebate,
  getUserStakeHistories,
  getUserInterestHistories,
  getUserCommissionHistories,
  getUserCoinTransactionHistories,
  updateVip,
};

export default exported;
