import { Button, Card, Form, InputNumber, Row, Col, Input  } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import SettingApi from "apis/SettingApi";
import CommonSlice from "stores/CommonSlice";

export default function FarmingSetting() {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { setting } = useAppSelector((state) => state.setting);
    const lpFarmingRate: any = setting.find((item) => item.key === "liquidity_farming_rate");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (lpFarmingRate) {
            form.setFieldsValue({
                iqt: lpFarmingRate.value.iqt * 100,
                bnb: lpFarmingRate.value.bnb * 100,
            });
        }
    }, [lpFarmingRate]);

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            const lpFarmingRateConfig = {
                key: "liquidity_farming_rate",
                value: {
                    iqt: values.iqt / 100,
                    bnb: values.bnb / 100,
                },
            };
            const response = await SettingApi.updateSetting(lpFarmingRateConfig);
            if (response) {
                dispatch(
                    CommonSlice.actions.showNotice({
                        type: "success",
                        message: "Success!",
                        description: "Update successful",
                    })
                );
            }
        } catch (error) {
            dispatch(
                CommonSlice.actions.showNotice({
                    type: "error",
                    message: "Error!",
                    description: "Update failed",
                })
            );
        } finally {
            setLoading(false);
        }
    };
    return (
        <Card title="LP Farming Rate (%)" bordered={false}>
            <Form form={form} name="lp-farming" onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="IQT"
                    name="iqt"
                    rules={[{ required: true, message: "Please input your value!" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="BNB"
                    name="bnb"
                    rules={[{ required: true, message: "Please input your value!" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}
