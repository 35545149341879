import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Col, Divider, Form, InputNumber, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import CommonSlice from "stores/CommonSlice";
import { update } from "stores/SettingSlice";
import { decimalAdjustFloor3 } from "utils/Helpers";

export default function StakingLevelSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { setting } = useAppSelector((state) => state.setting);
  const stakingInterest = setting.find(
    (item) => item.key === "staking_interest"
  );

  const onSetting = async (params: any) => {
    setLoading(true);
    try {
      const stakingInterestParams = {
        key: "staking_interest",
        value: params,
      };
      const result = unwrapResult(
        await dispatch(update(stakingInterestParams))
      );
      if (result) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values: any) => {
    if (stakingInterest) {
      const params = stakingInterest.value.map((item: any) => {
        const minValueKey = `minValueLevel${item.level}`;
        const maxValueKey = `maxValueLevel${item.level}`;
        const rate = `interestRateLevel${item.level}`;
        const updatedMinValue = values[minValueKey];
        const updatedMaxValue = values[maxValueKey];
        const updatedRateValue = values[rate];

        return {
          ...item,
          minValue: updatedMinValue,
          maxValue: updatedMaxValue,
          interestRate: updatedRateValue / 100,
        };
      });
      const resultArray = params.map((item: any) => {
        let newItem = {} as any;
        Object.keys(item).forEach((key) => {
          if (item[key] !== undefined) {
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
      onSetting(resultArray);
    }
  };

  useEffect(() => {
    if (stakingInterest) {
      stakingInterest.value.forEach((item: any) => {
        form.setFieldValue(`minValueLevel${item.level}`, item.minValue);
        form.setFieldValue(`maxValueLevel${item.level}`, item.maxValue);
        form.setFieldValue(
          `interestRateLevel${item.level}`,
          decimalAdjustFloor3(Math.round(item.interestRate * 100 * 1e12) / 1e12)
        );
      });
    }
  }, [stakingInterest]);

  return (
    <Card title="Staking Level" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        {stakingInterest &&
          stakingInterest.value.map((item: any, index: number) => (
            <Row key={index} style={{ margin: 0 }} gutter={[16, 16]}>
              <Divider>Level {item.level}</Divider>
              <Col span={8}>
                <Form.Item
                  label="Min Value"
                  name={`minValueLevel${item.level}`}
                  rules={[
                    { required: true, message: "Please input your value!" },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
                </Form.Item>
              </Col>
              {item.level != 4 ? (
                <Col span={8}>
                  <Form.Item
                    label="Max Value"
                    name={`maxValueLevel${item.level}`}
                    rules={[
                      { required: true, message: "Please input your value!" },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
                  </Form.Item>
                </Col>
              ) : null}
              <Col span={8}>
                <Form.Item
                  label="Interest Rate"
                  name={`interestRateLevel${item.level}`}
                  rules={[
                    { required: true, message: "Please input your value!" },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} addonAfter="%" />
                </Form.Item>
              </Col>
            </Row>
          ))}

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
