import { Table, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import configs from "../../../configs";

const { Text } = Typography;

type Props = {
  filter: any;
  onChangePage: (page: number) => void;
};

export default function List(props: Props) {
  const { histories } = useAppSelector((state) => state.prediction);

  const getStatus = (status: string) => {
    switch (status) {
      case "WAIT_ADMIN_CONFIRM":
        return "Admin Confirm";
      case "WAIT_NETWORK_CONFIRM":
        return "Pending";
      case "CANCELLED":
        return "Cancelled";
      case "SUCCESSFUL":
        return "Successful";
      case "FAILED":
        return "Failed";
    }
  }

  const columns: any = [
    {
      title: "Round",
      dataIndex: "round",
      key: "round",
      render: (value: any) => (
        <Tooltip title={value.shortId}>#{value.shortId}</Tooltip>
      ),
    },
    {
      title: "Timeframe",
      dataIndex: "round",
      key: "round",
      render: (value: any) => (
        <Tooltip title={value.timeframe}>{value.timeframe}m</Tooltip>
      ),
    },
    {
      title: "Control by",
      dataIndex: "controlBy",
      key: "controlBy",
      render: (value: any) => (
          <Text>{value?.username}</Text>
      ),
    },
    {
      title: "Position Config",
      dataIndex: "positionWin",
      key: "positionWin",
      render: (value: string) => (
          <Text
              strong
              type={
                value === "UP"
                    ? "success"
                    : value === "DOWN"
                        ? "danger"
                        : undefined
              }
          >
            {value}
          </Text>
      )
    },
    {
      title: "Position Win",
      dataIndex: "round",
      key: "round.positionWin",
      render: (value: any) => (
          <Text
              strong
              type={
                value.positionWin === "UP"
                    ? "success"
                    : value.positionWin === "DOWN"
                        ? "danger"
                        : undefined
              }
          >
            {value.positionWin ? value.positionWin: "..."}
          </Text>
      )
    },
    {
      title: "Status",
      dataIndex: "round",
      key: "status",
      render: (value: any) => (
        <Text
          strong
          type={
            value.status === "LIVE"
              ? "success"
              : value.status === "EXPIRED"
              ? "danger"
              : undefined
          }
        >
          {value.status}
        </Text>
      ),
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "time",
      render: (value: string) => (
          <span>
          {moment(value).format("DD/MM/YYYY")}
            <br />
            {moment(value).format("HH:mm")}
        </span>
      ),
      align: "center",
    },
  ];
  return (
    <div className="list-container">
      <Table
        dataSource={histories.datas.map((item) => ({
          key: item.id,
          ...item,
        }))}
        columns={columns}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 15,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: histories.meta.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => props.onChangePage(page),
        }}
      />
    </div>
  );
}
